export const IGNORE_SENDER = ['system', 'contact', 'internal']

export const MESSAGE_STATUS = {
  '-2': {
    icon: 'Close',
    value: null,
    label: 'Invalid',
  },
  '-1': {
    icon: 'Megaphone',
    value: -1,
    label: 'System',
  },
  0: {
    icon: 'Time',
    value: 0,
    label: 'Sending',
  },
  1: {
    icon: 'Check',
    value: 1,
    label: 'Sent',
  },
  2: {
    icon: 'CheckAll',
    value: 2,
    label: 'Delivered',
  },
  3: {
    icon: 'CheckAll',
    value: 3,
    label: 'Read',
    color: '#136CDC',
  },
  4: {
    icon: 'Close',
    value: 4,
    label: 'Error',
  },
}
