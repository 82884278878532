import Contact from './Contact'
import File from './File'
import List from './List'
import Location from './Location'
import Other from './Other'
import Template from './Template'
import Text from './Text'

const LAST_MESSAGE_DICT = {
  template: (props) => <Template {...props} />,
  contact: (props) => <Contact {...props} />,
  text: (props) => <Text {...props} />,
  file: (props) => <File {...props} />,
  list: (props) => <List {...props} />,
  location: (props) => <Location {...props} />,
  other: (props) => <Other {...props} />,
}

const LastMessage = (props) => (LAST_MESSAGE_DICT?.[props?.type] ? LAST_MESSAGE_DICT[props?.type](props) : LAST_MESSAGE_DICT['other'](props))

export default LastMessage
