import { useState } from 'react'

import { ModalCreateNewTicket, ProtectedComponent } from 'components'
import { Column, Icon, Input, Row, Typography } from 'design-system'
import { useClickout } from 'hooks'
import { useTicketStore } from 'store'

import PopoverTicketsFilter from '../../TicketsList/PopoverTicketsFilter'

const TicketsListHeader = () => {
  const [open, setOpen] = useState(null)
  const { setFilters, filters } = useTicketStore()

  const { attendant__in, attendant, department__in, is_solved } = filters || {}

  const { elementRef, openedState, triggerRef } = useClickout(false)

  const ACTIVE_FILTER = attendant__in?.length > 0 || department__in?.length > 0 || attendant || is_solved

  return (
    <Column>
      <Typography fontSize='18px' fontWeight='600'>
        Conversas
      </Typography>
      <Row alignItems='center' mt='16px' gap='16px'>
        <Input
          width='100%'
          placeholder='Pesquisar'
          type='search'
          value={filters.search}
          onChange={(event) => setFilters({ search: event.target.value.toLowerCase() })}
          icon='Search'
          iconPosition='left'
          iconProps={{ color: 'grey.100' }}
        />
        <Row position='relative'>
          <Icon ref={triggerRef} icon='Filter2' color={openedState ? 'grey.300' : 'grey.200'} cursor='pointer' size='lg' />
          {ACTIVE_FILTER && (
            <Column position='absolute' top='-4px' right='-2px' backgroundColor='secondary' width='8px' height='8px' borderRadius='100%' />
          )}
          {openedState && <PopoverTicketsFilter elementRef={elementRef} open={openedState} />}
        </Row>
        <ProtectedComponent allowedRoles={['manage_tickets']} unauthorizedComponent={false}>
          <Icon icon='Edit2' size='lg' color='grey.200' onClick={() => setOpen(true)} cursor='pointer' />
        </ProtectedComponent>
      </Row>
      {open && <ModalCreateNewTicket open={open} onClose={() => setOpen(null)} />}
    </Column>
  )
}

export default TicketsListHeader
