import { create } from 'zustand'

const useTicketStore = create((set) => ({
  selected_ticket: {},
  filters: { page_size: 20, page: 1, attendant: '', is_solved: '', ordering: '-last_message_created_at', department__in: [], attendant__in: [] },
  current_tab: 'pending,attending',
  loading_ticket: false,

  setSelectedTicket: (ticket) => set((state) => ({ selected_ticket: { ...state.selected_ticket, ...ticket } })),
  setFilters: (filters) => set((state) => ({ filters: { ...state.filters, ...filters } })),
  setCurrentTab: (tab) => set(() => ({ current_tab: tab })),
  setLoadingTicket: (state) => set(() => ({ loading_ticket: state })),
}))

export default useTicketStore
