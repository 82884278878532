import { badgeInstagramLogo, badgeWhatsappLogo } from 'assets/logos/social'

export const PLATFORM_SOCIAL = {
  whatsapp: {
    label: 'WhatsApp',
    logo: badgeWhatsappLogo,
  },
  instagram: {
    label: 'Instagram',
    logo: badgeInstagramLogo,
  },
}
