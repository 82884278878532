import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Alert } from 'components/common'
import { Button, Column, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Typography } from 'design-system'
import { notify } from 'helpers'
import { useAuth, useTicketsList } from 'hooks'
import { createTicket } from 'services'
import { useTicketStore } from 'store'

import ModalManageFiles from '../ModalManageFiles'

import { initialValues } from './constant'
import Information from './Information'
import PreviewTemplate from './PreviewTemplate'

const ModalCreateNewTicket = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [existTicket, setExistTicket] = useState(null)
  const [manageFile, setManageFile] = useState(null)

  const { setCurrentTab } = useTicketStore()
  const { addTicketToCache } = useTicketsList()
  const navigate = useNavigate()
  const {
    userData: { company_user },
  } = useAuth()

  const department = company_user?.departments?.[0] || ''

  const methods = useForm({ defaultValues: { ...initialValues, department } })

  const [watchConnection, template] = methods.watch(['connection', 'template'])

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      values.connection = values?.connection?.id
      values.contact = values.contact.id
      values.attendant = values.attendant.id
      values.tags = []
      values.department = values.department.id
      values.message = {
        message_type: 'template',
        template_params: values.template_params.map((param) => param.text).filter((item) => item),
        header: {
          media: values.template?.header?.default_media?.uuid,
        },
        template: values.template.id,
      }

      const { data } = await createTicket(values)

      addTicketToCache(data)
      setCurrentTab('pending,attending')

      notify.success('Nova conversa criada com sucesso.')
      navigate(`/tickets/${data.id}/`)
      onClose()
    } catch {
      notify.error('Não foi possível criar nova conversa.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal position='relative' size='md' open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader onClose={onClose}>
            <Typography variant='title' color='primary'>
              Iniciar nova conversa
            </Typography>
          </ModalHeader>
          <ModalBody>
            <Information setExistTicket={setExistTicket} loading={loading} />
            <PreviewTemplate defaultConnection={watchConnection} loading={loading} setManageFile={setManageFile} />
            {existTicket && (
              <Alert
                mt='16px'
                color='warning'
                text='Esse contato já possui uma conversa aberta'
                action={
                  <Typography
                    fontWeight='600'
                    color='secondary'
                    cursor='pointer'
                    onClick={() => {
                      navigate(`/tickets/${existTicket}`)
                      onClose()
                    }}
                  >
                    Acessar conversa
                  </Typography>
                }
              />
            )}
            {loading && (
              <Row alignItems='center' justifyContent='flex-end' mt='16px' gap='16px'>
                <Column>
                  <Spinner size='sm' />
                </Column>
                <Typography>Enviando template... Aguarde alguns segundos.</Typography>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button maxWidth='100px' variant='text' color='grey.300' onClick={onClose}>
              Cancelar
            </Button>
            <Button type='submit' maxWidth='100px' color={loading ? 'grey.300' : 'primary'} disabled={loading || existTicket}>
              {loading ? 'Enviando...' : 'Enviar'}
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
      {manageFile && (
        <ModalManageFiles
          open={manageFile}
          onClose={() => setManageFile(null)}
          categoryFilter={template?.header?.header_type?.media}
          setFieldValue={(file) => methods.setValue('template.header.default_media', file)}
        />
      )}
    </Modal>
  )
}

export default ModalCreateNewTicket
