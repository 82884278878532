import { Icon, Row, SanitizeText } from 'design-system'

import { MESSAGE_ICON } from './constants'

const File = ({ ticket, solved }) => {
  const { last_message } = ticket || {}
  const { files } = last_message || {}
  const { file_category, caption, file_name } = files?.[0] || {}

  const message_icon = MESSAGE_ICON?.[file_category?.value] || MESSAGE_ICON['other']

  const text = file_name || caption || 'Arquivo'

  return (
    <Row alignItems='center' gap='2px'>
      <Icon size='14' color='grey.300' icon={message_icon} />
      <SanitizeText text={text} fontWeight={solved ? '600' : '400'} fontSize='14px' width='200px' ellipsis />
    </Row>
  )
}

export default File
