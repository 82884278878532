import { useParams } from 'react-router-dom'

import { EmptyTicketContainer, TicketContainer } from 'components'
import { Row } from 'design-system'
import { withAuthorization } from 'helpers'
import { useWindowResize } from 'hooks'
import { TicketProvider } from 'providers'

import TicketsList from './TicketsList'

const Tickets = () => {
  const { ticketId } = useParams()
  const { isDesktop, isMobile, isTablet } = useWindowResize()

  return (
    <TicketProvider>
      <Row height='100vh' overflow='hidden'>
        {isDesktop && <TicketsList />}
        {(isMobile || isTablet) && !ticketId && <TicketsList />}
        {ticketId ? <TicketContainer ticketId={ticketId} /> : isDesktop && <EmptyTicketContainer />}
      </Row>
    </TicketProvider>
  )
}

export default withAuthorization(Tickets, ['access_tickets'])
