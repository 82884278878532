import { Badge, ColorChip, Row, Timer, Typography } from 'design-system'

const Footer = ({ solved, closed_at, last_message }) => {
  const { created_at } = last_message || {}

  const TIME = closed_at ? closed_at : created_at

  return (
    <Row width='100%' mt='4px' justifyContent='flex-end' alignItems='center'>
      <Badge backgroundColor='grey.40'>
        <Typography fontSize='12px' fontStyle='italic' color='grey.100' fontWeight={solved ? '600' : '400'}>
          {closed_at && `Encerrado `}
          <Timer initialTime={TIME} />
        </Typography>
        {solved && <ColorChip color='blue.80' width='10px' height='10px' />}
      </Badge>
    </Row>
  )
}

export default Footer
