import { useRef, useState } from 'react'

import { Button, Column, Typography } from 'design-system'
import { notify } from 'helpers'
import { uploadFile } from 'services'

import ModalNewPaste from '../ModalNewPaste'

const ActionButton = ({ categoryFilter, setLoading, setFolders, selectedFolder, setFiles, connection, loading }) => {
  const [open, setOpen] = useState(null)

  const categorySizes = {
    image: { label: 'Imagens', size: 5 * 1024 * 1024, max: '5MB' },
    video: { label: 'Vídeos', size: 1 * 1024 * 1024, max: '16MB' },
    application: { label: 'Outros documentos', size: 100 * 1024 * 1024, max: '100MB' },
  }

  const inputFileRef = useRef(null)

  const handleUploadFile = async ({ target }) => {
    try {
      setLoading(true)
      if (target?.files?.length === 0) return false

      const file = categorySizes?.[categoryFilter] || categorySizes['application']

      if (target?.files?.[0].size > file.size) {
        notify.error(`O tamanho máximo permitido para o upload é ${file.max}. Por favor, escolha um arquivo menor.`)
        return (inputFileRef.current.value = '')
      }

      const formData = new FormData()

      formData.append('connection', selectedFolder?.connection?.id)
      formData.append('folder', selectedFolder?.id)
      formData.append('file', target.files[0])

      const { data } = await uploadFile(formData)

      setFiles((prevState) => [data, ...prevState])

      notify.success('Arquivo salvo com sucesso.')
    } catch (err) {
      if (err?.response?.data?.file?.detail) return notify.error(err?.response?.data?.file?.detail)

      notify.error('Não foi possível salvar arquivo no momento.')
    } finally {
      setLoading(false)
      if (inputFileRef.current) inputFileRef.current.value = ''
    }
  }

  const acceptsFileDict = {
    image: '.jpg, .jpeg, .png',
    video: '.mp4',
    document: '.pdf, .doc, .docx, .xls, .xlsx, .csv, .txt',
  }

  return (
    <Column>
      {selectedFolder?.id && (
        <>
          <Button iconLeft='Plus' onClick={() => inputFileRef?.current?.click()} disabled={loading}>
            Novo arquivo
          </Button>
          <Typography
            fontSize='12px'
            fontStyle='italic'
          >{`Tamanho máximo ${categorySizes?.[categoryFilter]?.max || categorySizes['application'].max}`}</Typography>
          <input ref={inputFileRef} hidden type='file' onChange={handleUploadFile} accept={acceptsFileDict[categoryFilter]} />
        </>
      )}
      {!selectedFolder && (
        <Button iconLeft='Plus' onClick={() => setOpen(true)} disabled={!connection}>
          Criar nova pasta
        </Button>
      )}
      {open && <ModalNewPaste open={open} connection={connection} onClose={() => setOpen(null)} setFolders={setFolders} />}
    </Column>
  )
}

export default ActionButton
