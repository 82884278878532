import { robotTagsBW } from 'assets/ilustrations'
import { Column, Image, Typography } from 'design-system'

const TicketsListEmpty = () => {
  return (
    <Column mt='50px' alignItems='center' justifyContent='center'>
      <Image width='110px' src={robotTagsBW} />
      <Typography mt='24px'>Sem conversas por aqui</Typography>
    </Column>
  )
}

export default TicketsListEmpty
