import { GlobalConfigs } from 'configs'
import { AuthProvider, QueryProvider } from 'providers'
import Routes from 'routes'

const App = () => {
  return (
    <QueryProvider>
      <GlobalConfigs>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </GlobalConfigs>
    </QueryProvider>
  )
}

export default App
