import { showDepartmentsList, showDepartmentsListSelect } from 'services'

const fetch = async (params) => {
  const type = params.queryKey[1]

  const filters = {
    page_size: 1000,
    ...params.queryKey[2],
  }

  const response = type === 'full' ? await showDepartmentsList(filters) : await showDepartmentsListSelect(filters)
  if (!response.data) throw new Error('Erro ao buscar departamentos')
  return response.data
}

export default fetch
