import { useCallback, useRef, useState } from 'react'

import { Column } from 'design-system'
import { useTicketsList } from 'hooks'

import SkeletonList from './SkeletonList'
import TicketCard from './TicketCard'
import TicketsListEmpty from './TicketsListEmpty'
import TicketsListHeader from './TicketsListHeader'
import TicketsTab from './TicketsTab'

const TicketsList = () => {
  const searchState = useState('')
  const observer = useRef(null)

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useTicketsList()

  const tickets = data?.pages.flatMap((page) => page.results) || []

  const lastTicketRef = useCallback(
    (node) => {
      if (isLoading || isFetchingNextPage) return
      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage()
          }
        },
        { rootMargin: '500px', threshold: 1.0 },
      )

      if (node) observer.current.observe(node)
    },
    [isLoading, isFetchingNextPage, hasNextPage, fetchNextPage],
  )

  return (
    <Column
      width='100%'
      maxWidth={['100%', '100%', '400px']}
      overflow='auto'
      border='1px solid'
      borderColor='grey.50'
      backgroundColor='white'
      p='16px'
    >
      <TicketsListHeader searchState={searchState} />
      <TicketsTab />
      {isLoading ? (
        <SkeletonList />
      ) : (
        <Column mt='6px' pb='24px' pr='10px' overflow='auto'>
          {tickets.length > 0 ? (
            tickets.map((ticket, index) => (
              <Column ref={index === tickets.length - 1 ? lastTicketRef : null} key={ticket.id}>
                <TicketCard ticket={ticket} />
              </Column>
            ))
          ) : (
            <TicketsListEmpty />
          )}
        </Column>
      )}
    </Column>
  )
}

export default TicketsList
