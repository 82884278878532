import { Row, Typography } from 'design-system'

const Other = ({ ticket, solved }) => {
  const { last_message } = ticket || {}

  const text = last_message?.body?.text || 'Mensagem desconhecida'

  return (
    <Row alignItems='center' gap='4px'>
      <Typography fontWeight={solved ? '600' : '400'} fontSize='14px' width='200px' ellipsis>
        {text}
      </Typography>
    </Row>
  )
}

export default Other
