import { Icon, Row, SanitizeText } from 'design-system'

const List = ({ ticket, solved }) => {
  const { last_message } = ticket || {}

  const text = last_message?.body?.text || 'Listagem'

  return (
    <Row alignItems='center' gap='4px'>
      <Icon icon='Megaphone' size='14' color='grey.300' />
      <SanitizeText text={text} fontWeight={solved ? '600' : '400'} fontSize='14px' width='200px' ellipsis />
    </Row>
  )
}

export default List
