import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Avatar, Column, Row } from 'design-system'
import { notify } from 'helpers'
import { useAuth } from 'hooks'
import { useTicketStore } from 'store'

import { PLATFORM_SOCIAL } from './constants'
import Footer from './Footer'
import Header from './Header'
import LastMessage from './LastMessage'

const TicketCard = ({ ticket, ...props }) => {
  const { setSelectedTicket } = useTicketStore()

  const navigate = useNavigate()
  const {
    userData: { company_user },
  } = useAuth()

  const { solved_status, department, attendant, id, contact, closed_at, last_message } = ticket || {}

  const solved = !closed_at && !solved_status
  const badge = PLATFORM_SOCIAL?.['whatsapp']

  const access_ticket = useMemo(() => {
    if (company_user?.owner) return true
    if (!department) return true
    if (department?.public_tickets) return true
    if (company_user?.departments.some((dp) => dp.id === department?.id)) return true
    if (attendant === null) return true

    return false
  }, [company_user, department, attendant])

  const handleClick = useCallback(() => {
    if (access_ticket) {
      setSelectedTicket(ticket)
      navigate(`/tickets/${id}`)
    } else {
      notify.warning('Você não pode acessar o atendimento de outro atendente.')
    }
  }, [access_ticket, setSelectedTicket, navigate, ticket, id])

  return (
    <Row
      p='8px'
      borderRight='none'
      borderTop='1px solid'
      borderColor='grey.50'
      borderLeft='5px solid'
      borderLeftColor={department?.color || 'grey.50'}
      borderRadius='8px 0 0 8px'
      gap='16px'
      className='cursor-pointer'
      onClick={handleClick}
      {...props}
    >
      <Column>
        <Avatar size='md' square src={contact?.picture} alt={contact?.name} badge={badge?.logo} />
      </Column>
      <Column width='100%'>
        <Header solved={solved} contact={contact} attendant={attendant} />
        <LastMessage type={last_message?.message_type} solved={solved} ticket={ticket} />
        <Footer solved={solved} closed_at={closed_at} last_message={last_message} />
      </Column>
    </Row>
  )
}

export default TicketCard
