import { Badge, Row, Tooltip, Typography } from 'design-system'

const Header = ({ solved, contact, attendant }) => {
  const { name: contact_name, dial_code } = contact || {}
  const { name: attendant_name } = attendant || {}

  return (
    <Row justifyContent='space-between'>
      <Tooltip title={`${contact_name} | ${dial_code}`}>
        <Typography ellipsis fontSize='14px' fontWeight={solved ? '600' : '400'} width='150px'>
          {contact_name}
        </Typography>
      </Tooltip>
      {attendant && <Badge width='100px' backgroundColor='purple.50' title={attendant_name} fontStyle={{ fontSize: '12px', color: 'purple.400' }} />}
    </Row>
  )
}
export default Header
