import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'

import useDebounce from 'hooks/custom/useDebounce'
import { useTicketStore } from 'store'

import fetch from './fetch'

const useTicketsList = () => {
  const queryClient = useQueryClient()
  const { filters, current_tab } = useTicketStore()
  const filtersDebounced = useDebounce(filters, 500)
  const status__in = useDebounce(current_tab, 500)

  const fetchFilters = { ...filtersDebounced, status__in }

  const query = useInfiniteQuery({
    queryKey: ['tickets', fetchFilters],
    queryFn: fetch,
    initialPageParam: 1,
    getNextPageParam: (page) => page.next ?? undefined,
  })

  const findTicketInCache = (ticketId) => {
    const queryData = queryClient.getQueryData(['tickets', fetchFilters])

    if (!queryData) return null

    for (const page of queryData.pages) {
      const foundTicket = page.results.find((ticket) => ticket.id === ticketId)
      if (foundTicket) return foundTicket
    }

    return null
  }

  const addTicketToCache = (newTicket) => {
    queryClient.setQueryData(['tickets', fetchFilters], (oldData) => {
      if (!oldData) {
        return {
          pages: [{ results: [newTicket] }],
          pageParams: [1],
        }
      }
      return {
        ...oldData,
        pages: oldData.pages.map((page, index) => (index === 0 ? { ...page, results: [newTicket, ...page.results] } : page)),
      }
    })
  }

  const updateTicketInCache = (updatedTicket) => {
    queryClient.setQueryData(['tickets', fetchFilters], (oldData) => {
      if (!oldData) return oldData

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          results: page.results.map((ticket) => (ticket.id === updatedTicket.id ? { ...ticket, ...updatedTicket } : ticket)),
        })),
      }
    })
  }

  const removeTicketFromCache = (ticketId) => {
    queryClient.setQueryData(['tickets', fetchFilters], (oldData) => {
      if (!oldData) return oldData
      console.log(ticketId, oldData)
      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          results: page.results.filter((ticket) => ticket.id !== ticketId),
        })),
      }
    })
  }

  return { ...query, findTicketInCache, addTicketToCache, updateTicketInCache, removeTicketFromCache }
}

export default useTicketsList
