import { useEffect, useRef, useState } from 'react'

const useClickout = (initialState) => {
  const triggerRef = useRef(null)
  const elementRef = useRef(null)

  const [openedState, setOpenedState] = useState(initialState)

  const handleClickoutSide = (event) => {
    if (triggerRef.current && triggerRef.current.contains(event.target)) {
      return setOpenedState(!openedState)
    }

    if (elementRef.current && !elementRef.current.contains(event.target)) {
      return setOpenedState(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickoutSide, true)
    return () => document.removeEventListener('click', handleClickoutSide, true)
  })

  return {
    triggerRef,
    elementRef,
    openedState,
    setOpenedState,
  }
}

export default useClickout
