import { showTicketList } from 'services'

const fetch = async (params) => {
  const { status__in, attendant, department__in, attendant__in, is_solved, ordering, page_size } = params.queryKey[1]
  const { pageParam } = params

  const filters = {
    page: pageParam,
    page_size,
    status__in,
    attendant,
    ordering,
    ...(is_solved && { is_solved: is_solved === 'solved' ? true : false }),
    ...(department__in.length > 0 && { department__in: department__in.join(',') }),
    ...(attendant__in.length > 0 && { attendant__in: attendant__in.join(',') }),
  }

  const { data } = await showTicketList(filters)

  if (!data) throw new Error('Erro ao buscar tickets')
  return data
}

export default fetch
