import { memo } from 'react'

import { Column, ListItem, Popover, Typography } from 'design-system'
import { useAuth } from 'hooks'
import { useTicketStore } from 'store'

import Attendant from './Attendant'
import Department from './Department'

const PopoverTicketsFilter = ({ open, elementRef, ...props }) => {
  const { filters, setFilters } = useTicketStore()

  const { is_solved, attendant } = filters

  const {
    userData: { company_user },
  } = useAuth()

  return (
    <Popover ref={elementRef} top='24px' right='0' minWidth='250px' open={open} {...props}>
      <Column p='8px'>
        <Typography as='span' color='grey.400' fontWeight='600' notSelectable>
          Conversas
        </Typography>
      </Column>
      <ListItem active={attendant === ''} onClick={() => setFilters({ attendant: '' })}>
        Todas as conversas
      </ListItem>
      <ListItem active={attendant === company_user?.id} onClick={() => setFilters({ attendant: company_user?.id })}>
        Minhas conversas
      </ListItem>
      <Column borderTop='3px solid #E8E8E8'>
        <ListItem active={is_solved === 'solved'} onClick={() => setFilters({ is_solved: is_solved === 'solved' ? '' : 'solved' })}>
          Lida
        </ListItem>
        <ListItem active={is_solved === 'not_solved'} onClick={() => setFilters({ is_solved: is_solved === 'not_solved' ? '' : 'not_solved' })}>
          Não lida
        </ListItem>
      </Column>
      <Department />
      <Attendant />
    </Popover>
  )
}

export default memo(PopoverTicketsFilter)
