import { useQuery } from '@tanstack/react-query'

import useDebounce from 'hooks/custom/useDebounce'

import fetch from './fetch'

const useAttendantsList = (params) => {
  const { type, filters, ...rest } = params || { type: 'full' }

  const filtersDebounced = useDebounce(filters, 500)

  return useQuery({
    queryKey: ['attendants', type, filtersDebounced],
    queryFn: fetch,
    ...rest,
  })
}

export default useAttendantsList
