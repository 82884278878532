import DOMPurify from 'dompurify'

import Typography from 'design-system/Typography'

const convertToHtml = (text) => {
  return text
    .replace(/\*(.*?)\*/g, '$1')
    .replace(/_(.*?)_/g, '<i>$1</i>')
    .replace(/~(.*?)~/g, '<s>$1</s>')
}

const SanitizeText = ({ text, ...rest }) => {
  const sanitizedHtml = DOMPurify.sanitize(convertToHtml(text))

  return <Typography as='p' ellipsis dangerouslySetInnerHTML={{ __html: sanitizedHtml }} {...rest} />
}

export default SanitizeText
