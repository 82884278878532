import { Row, SanitizeText } from 'design-system'

const Template = ({ ticket, solved }) => {
  const { last_message } = ticket || {}

  const text = last_message?.body?.text || 'Template'

  return (
    <Row alignItems='center' gap='4px'>
      <SanitizeText text={text} fontWeight={solved ? '600' : '400'} fontSize='14px' width='200px' ellipsis />
    </Row>
  )
}

export default Template
