import { colors } from 'configs'
import { ColorChip, Column, Row, Typography } from 'design-system'
import { useTicketStore } from 'store'

const TicketsTab = ({ ...props }) => {
  const current_tab = useTicketStore((state) => state.current_tab)
  const setCurrentTab = useTicketStore((state) => state.setCurrentTab)

  const tabs = [
    {
      label: 'Em atendimento',
      key: 'pending,attending',
      count: 0,
    },
    {
      label: 'Encerradas',
      key: 'closed',
      count: 0,
    },
  ]

  return (
    <Row m='18px 0 12px' {...props}>
      {tabs.map(({ label, count, key }) => {
        const active = current_tab === key

        return (
          <Column
            role='tab'
            aria-selected={active}
            key={key}
            width='100%'
            p='8px'
            borderBottom='1px solid transparent'
            borderBottomColor={active ? colors.primary : colors.grey[50]}
            alignItems='center'
            className='cursor-pointer'
            onClick={() => setCurrentTab(key)}
          >
            <Row alignItems='center' gap='8px'>
              <Typography whiteSpace='nowrap' fontWeight='600' color={active ? 'primary' : 'text'} notSelectable>
                {label}
              </Typography>
              {count > 0 && (
                <ColorChip width='20px' height='20px' alignItems='center' justifyContent='center' backgroundColor={colors.violet[100]}>
                  <Typography color='white' fontWeight='700' fontSize='10px' textAlign='center'>
                    {count > 99 ? '99+' : count}
                  </Typography>
                </ColorChip>
              )}
            </Row>
          </Column>
        )
      })}
    </Row>
  )
}

export default TicketsTab
