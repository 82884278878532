import { Icon, Row, SanitizeText } from 'design-system'

import { IGNORE_SENDER, MESSAGE_STATUS } from './constants'

const Text = ({ ticket, solved }) => {
  const { sender_type, last_message } = ticket || {}
  const { status, body } = last_message || {}

  const current_status = MESSAGE_STATUS?.[status?.value]

  const ignore_sender = !IGNORE_SENDER.includes(sender_type) || sent_through_whatsapp

  const text = body?.text || 'Mensagem de texto'

  return (
    <Row alignItems='center' gap='4px'>
      {ignore_sender && current_status && <Icon size='14' icon={current_status?.icon} color={current_status.color} />}
      <SanitizeText text={text} fontSize='14px' fontWeight={solved ? '600' : '400'} width='200px' ellipsis />
    </Row>
  )
}

export default Text
