import { Icon, Row, SanitizeText } from 'design-system'

const Location = ({ ticket, solved }) => {
  const { last_message } = ticket || {}

  const text = last_message?.body?.text || 'Localização'

  return (
    <Row alignItems='center' gap='4px'>
      <Row alignItems='center' gap='4px'>
        <Icon icon='Location' size='14' color='grey.300' />
        <SanitizeText text={text} fontWeight={solved ? '600' : '400'} fontSize='14px' width='200px' ellipsis />
      </Row>
    </Row>
  )
}

export default Location
