import { memo } from 'react'

import { Checkbox, ColorChip, Column, ListItem, Row, Spinner, Typography } from 'design-system'
import { useDepartmentsList } from 'hooks'
import { useTicketStore } from 'store'

const Department = () => {
  const { setFilters, filters } = useTicketStore()
  const { data, isLoading } = useDepartmentsList({ type: 'select', filters: { active: true } })

  const { department__in } = filters
  const departments = data?.results || []

  const handleSelect = (dep) => {
    if (department__in.find((d) => d === dep)) {
      setFilters({ department__in: department__in.filter((d) => d !== dep) })
    } else {
      setFilters({ department__in: [...department__in, dep] })
    }
  }

  return (
    <Column>
      <Column p='8px' borderTop='4px solid #E8E8E8'>
        <Typography as='span' color='#474747' fontWeight='600' notSelectable>
          Departamentos
        </Typography>
      </Column>
      {isLoading ? (
        <Column p='32px' alignItems='center' justifyContent='center'>
          <Spinner size='sm' />
        </Column>
      ) : (
        <Column maxHeight='200px' overflow='auto'>
          <ListItem justifyContent='space-between' onClick={() => handleSelect('null')}>
            <Row gap='4px' className='cursor-pointer'>
              <Checkbox checked={!!department__in?.find((q) => q === 'null')} />
              <Typography notSelectable>Sem departamento</Typography>
            </Row>
            <ColorChip color='grey.50' width='11px' height='11px' />
          </ListItem>
          {departments.map(({ id, name, color }) => (
            <ListItem key={`${name}_${id}`} justifyContent='space-between' onClick={() => handleSelect(id)}>
              <Row gap='4px' className='cursor-pointer'>
                <Checkbox checked={!!department__in?.find((q) => q === id)} />
                <Typography notSelectable>{name}</Typography>
              </Row>
              <ColorChip color={color} width='11px' height='11px' />
            </ListItem>
          ))}
        </Column>
      )}
    </Column>
  )
}
export default memo(Department)
