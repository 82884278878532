import { format } from 'date-fns'
import md5 from 'md5'
import { createContext, useCallback, useEffect, useMemo, useRef } from 'react'

import { messageStatusDict, system } from 'configs'
import { useAuth, useTicketsList } from 'hooks'
import { socket } from 'providers/socket'
import { useTicketStore } from 'store'

import {
  useTicketReducer,
  actionTicketTypes,
  actionMessagesTypes,
  actionSelectedTicketTypes,
  actionCurrentStatusTypes,
  actionRepliedMessage,
  actionCountTickets,
} from './store'

export const TicketContext = createContext({})

export const TicketProvider = ({ children }) => {
  const [state, dispatch] = useTicketReducer()

  const {
    userData: { company_user },
  } = useAuth()

  const { SOCKET_ENVIRONMENT } = system

  const connectedRoomsRef = useRef([])

  const { selected_ticket } = state

  const { filters, current_tab } = useTicketStore()
  const { updateTicketInCache, removeTicketFromCache, addTicketToCache, findTicketInCache } = useTicketsList()

  const PENDING_OR_ATTENDING_TAB = current_tab === 'pending,attending'
  const CLOSED_TAB = current_tab === 'closed'

  const roomCode = useMemo(() => `${SOCKET_ENVIRONMENT}_chatgds_ticket_${md5(`${selected_ticket?.id}`)}`, [selected_ticket, SOCKET_ENVIRONMENT])

  const handlerEventMessage = useCallback(
    ({ data, event }) => {
      if (event === 'created' && selected_ticket.id === data.id) {
        dispatch({
          type: actionMessagesTypes.ADD_MESSAGE,
          payload: { ...data, key: format(data.created_at, 'dd/MM/yyyy') },
        })
        if (data?.ticket?.id) {
          dispatch({
            type: actionSelectedTicketTypes.UPDATE_SELECTED_TICKET,
            payload: { ...data.ticket },
          })
        }
      }

      if (event === 'status-update') {
        if (data?.ticket?.id) {
          const ticket = findTicketInCache(data.ticket.id)

          updateTicketInCache({
            ...ticket,
            last_message: {
              ...ticket?.last_message,
              status: messageStatusDict[data?.current_status],
            },
          })
        }
        if (data?.id) {
          dispatch({
            type: actionMessagesTypes.UPDATE_MESSAGES,
            payload: {
              id: data.id,
              status: messageStatusDict[data?.current_status],
              key: format(data?.created_at, 'dd/MM/yyyy'),
            },
          })
        }
      }
    },
    [dispatch, selected_ticket],
  )

  const handlerEventTicket = useCallback(
    ({ data, event }) => {
      const activeTicket = Number(selected_ticket?.id) === Number(data?.id)
      const findTicket = findTicketInCache(data.id)
      const hasActiveUserFilter = filters?.attendant === 'attendant'

      const TICKET_STATUS = ['pending', 'attending'].includes(data.status)
      const TICKET_CLOSED = data.status === 'closed'

      const IN_CURRENT_TAB = (PENDING_OR_ATTENDING_TAB && TICKET_STATUS) || (CLOSED_TAB && TICKET_CLOSED)

      if (hasActiveUserFilter && data?.attendant?.id !== company_user?.id) return

      if (event === 'update') {
        if (activeTicket) {
          dispatch({ type: actionSelectedTicketTypes.UPDATE_SELECTED_TICKET, payload: data })
        }
        if (findTicket) {
          if (IN_CURRENT_TAB) {
            updateTicketInCache(data)
          } else {
            removeTicketFromCache(data.id)
          }
        }
      }

      if (event === 'created' && !findTicket && IN_CURRENT_TAB) {
        addTicketToCache(data)
      }
    },
    [dispatch, selected_ticket?.id, PENDING_OR_ATTENDING_TAB, CLOSED_TAB, company_user, filters, findTicketInCache],
  )

  useEffect(() => {
    if (!connectedRoomsRef.current.includes(roomCode)) {
      socket.emit('join-room', roomCode)
      connectedRoomsRef.current.push(roomCode)
    }

    socket.on('ticket-message', handlerEventMessage)
    socket.on('ticket', handlerEventTicket)

    return () => {
      socket.off('ticket-message', handlerEventMessage)
      socket.off('ticket', handlerEventTicket)
    }
  }, [roomCode, handlerEventMessage, handlerEventTicket, company_user])

  return (
    <TicketContext.Provider
      value={{
        state,
        dispatch,
        actionTicketTypes,
        actionMessagesTypes,
        actionSelectedTicketTypes,
        actionCurrentStatusTypes,
        actionRepliedMessage,
        actionCountTickets,
      }}
    >
      {children}
    </TicketContext.Provider>
  )
}

export default TicketProvider
