import { useQuery } from '@tanstack/react-query'

import useDebounce from 'hooks/custom/useDebounce'

import fetch from './fetch'

const useDepartmentsList = (params) => {
  const { type, filters } = params || { type: 'full' }

  const filtersDebounced = useDebounce(filters, 500)

  return useQuery({
    queryKey: ['departments', type, filtersDebounced],
    queryFn: fetch,
  })
}

export default useDepartmentsList
